/*******************************
     User Global Variables
*******************************/
/* Checkbox */
/* Focused Checkbox */
/* Radio */
/* Focused Radio */
/* Slider */
/* Focused Slider */
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #a6d3a6;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #4a964a;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #426B42;
}
